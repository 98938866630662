<template>
  <div class="new-client panel is-primary">
    <h1 class="title is-6 panel-heading mb-0">
      Редактирование клиента (нерезидент)
    </h1>
    <form class="box" @submit.prevent="submit">
      <div class="columns is-multiline">
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field label="Вид документа">
            <b-radio-button
              v-model="isPassport"
              native-value="0"
              type="is-primary"
              expanded
            >
              <b-icon icon="id-card" size="is-medium"></b-icon>
              <span>Вид на жительство</span>
            </b-radio-button>

            <b-radio-button
              v-model="isPassport"
              native-value="1"
              type="is-primary"
              expanded
            >
              <b-icon icon="passport" size="is-medium"></b-icon>
              <span>Паспорт</span>
            </b-radio-button>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            label="Фамилия"
            :type="{
              'is-danger': $v.lastName.$error || 'lastName' in serverValid,
              'is-success': !$v.lastName.$invalid && $v.lastName.$dirty,
            }"
            :message="{ 'Некорректно указана фамилия': $v.lastName.$error }"
          >
            <b-input
              placeholder="Фамилия"
              v-model.trim="lastName"
              @blur="
                onTouchField('lastName');
                removeKeyFromServerValid('lastName');
              "
            ></b-input>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            label="Имя"
            :type="{
              'is-danger': $v.firstName.$error || 'firstName' in serverValid,
              'is-success': !$v.firstName.$invalid && $v.firstName.$dirty,
            }"
            :message="{ 'Некорректно указано имя': $v.firstName.$error }"
          >
            <b-input
              placeholder="Имя"
              v-model.trim="firstName"
              @blur="
                onTouchField('firstName');
                removeKeyFromServerValid('firstName');
              "
            ></b-input>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field label="Пол">
            <b-radio-button
              v-model="gender"
              native-value="0"
              type="is-primary"
              expanded
            >
              <b-icon icon="male" size="is-medium"></b-icon>
              <span>Мужской</span>
            </b-radio-button>

            <b-radio-button
              v-model="gender"
              native-value="1"
              type="is-primary"
              expanded
            >
              <b-icon icon="female" size="is-medium"></b-icon>
              <span>Женский</span>
            </b-radio-button>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            :label="textSeries"
            :type="{
              'is-danger':
                $v.passportSeries.$error || 'passportSeries' in serverValid,
              'is-success':
                !$v.passportSeries.$invalid && $v.passportSeries.$dirty,
            }"
            :message="{ 'Некорректно заполено поле': $v.passportSeries.$error }"
          >
            <b-input
              placeholder=""
              v-model.trim="passportSeries"
              @blur="
                onTouchField('passportSeries');
                removeKeyFromServerValid('passportSeries');
              "
            ></b-input>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            :label="textNumber"
            :type="{
              'is-danger':
                $v.passportNumber.$error || 'passportNumber' in serverValid,
              'is-success':
                !$v.passportNumber.$invalid && $v.passportNumber.$dirty,
            }"
            :message="{ 'Некорректно заполено поле': $v.passportNumber.$error }"
          >
            <b-input
              placeholder=""
              v-model.trim="passportNumber"
              @blur="
                onTouchField('passportNumber');
                removeKeyFromServerValid('passportNumber');
              "
            ></b-input>
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            label="Телефон"
            :type="{
              'is-danger': $v.phone.$error || 'phone' in serverValid,
              'is-success': !$v.phone.$invalid && $v.phone.$dirty,
            }"
            :message="{ 'Телефон в формате +7(900)000-00-00': $v.phone.$error }"
          >
            <IMaskComponent
              placeholder="+7(900)000-00-00"
              maxlength="16"
              inputmode="numeric"
              class="input"
              :class="{
                'is-danger': $v.phone.$error || 'phone' in serverValid,
                'is-success': !$v.phone.$invalid && $v.phone.$dirty,
              }"
              :mask="'+{7}({9}00)000-00-00'"
              v-model.trim="phone"
              @blur="
                onTouchField('phone');
                removeKeyFromServerValid('phone');
              "
            />
          </b-field>
        </div>
        <div
          class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"
        >
          <b-field
            label="Email"
            :type="{
              'is-danger': $v.email.$error || 'email' in serverValid,
              'is-success': !$v.email.$invalid && $v.email.$dirty,
            }"
            :message="{ 'Некорректно указан email': $v.email.$error }"
          >
            <b-input
              placeholder="example@mail.com"
              v-model.trim="email"
              @blur="
                onTouchField('email');
                removeKeyFromServerValid('email');
              "
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="button-wrapper">
        <b-button type="is-info" native-type="submit">Обновить</b-button>
      </div>
    </form>
  </div>
</template>

<script>
import {
  minLength,
  maxLength,
  required,
  email,
} from 'vuelidate/lib/validators';
import { IMaskComponent } from 'vue-imask';
import { onTouchField, removeKeyFromServerValid } from '@/utils/helpers';

export default {
  name: 'UpdateClientNonResident',
  data() {
    return {
      phone: '',
      email: '',
      firstName: '',
      lastName: '',
      passportSeries: '',
      passportNumber: '',
      gender: '',
      isPassport: '1',
      textSeries: 'Серия паспорта',
      textNumber: 'Номер паспорта',
      onTouchField,
      removeKeyFromServerValid,
      serverValid: {},
    };
  },
  async created() {
    this.$store.commit('toggleLoader', true);
    await this.$store.dispatch('getme');
    await this.$store.dispatch('client/getClient', this.$route.params.id);
    this.$store.commit('toggleLoader', false);
  },
  computed: {
    noValid() {
      return this.$store.state.client.noValid;
    },
    clientData() {
      return this.$store.state.client.clientData;
    },
  },
  watch: {
    isPassport(val) {
      this.textSeries = Number(val) ? 'Серия паспорта' : 'Серия документа';
      this.textNumber = Number(val) ? 'Номер паспорта' : 'Номер документа';
    },
    noValid(fields) {
      this.serverValid = { ...fields };
    },
    clientData(data) {
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          this[key] = data[key];
        }
      }
    },
  },
  methods: {
    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const clientData = {
        phone: this.phone,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        passportSeries: this.passportSeries,
        passportNumber: this.passportNumber,
        gender: this.gender,
        isPassport: this.isPassport,
        id: this.clientData.id,
      };

      this.$store.dispatch('client/updateClient', clientData);
    },
  },
  validations: {
    phone: {
      minLength: minLength(16),
      maxLength: maxLength(16),
    },
    email: {
      email,
      maxLength: maxLength(40),
    },
    lastName: {
      minLength: minLength(2),
      maxLength: maxLength(40),
      required,
    },
    firstName: {
      minLength: minLength(2),
      maxLength: maxLength(40),
      required,
    },
    passportSeries: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(6),
    },
    passportNumber: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(10),
    },
  },
  components: { IMaskComponent },
};
</script>

<style lang="scss">
.new-client {
  display: flex;
  flex-direction: column;
  height: 100%;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;

    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: auto;
    }
  }
}
</style>
